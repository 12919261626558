import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import blogEntries from '../features/blogs/components/BlogEntries.json';

const chunkArray = (myArray: any[], chunk_size: number) => {
  const results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }
  return results;
};

const BlogsCarousel: React.FC = () => {
  const groupedBlogEntries = chunkArray([...blogEntries], 3);

  return (
    <section id="featured-blogs" className="py-36 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Featured Blogs</h2>
        <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
          {groupedBlogEntries.map((group, index) => (
            <div key={index} className="flex flex-wrap justify-center h-[500px]">
              {group.map((blog) => (
                <div key={blog.id} className="w-full md:w-1/2 lg:w-1/3 px-2 h-full">
                  <Link to={`/blog/${blog.id}`} className="block bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow overflow-hidden h-full">
                    <img src={blog.image} alt={blog.title} className="w-full h-[70%] object-cover" />
                    <div className="p-4">
                      <h2 className="text-xl font-bold mb-2 text-gray-800">{blog.title}</h2>
                      <p className="text-blue-600 hover:underline">Read More</p>
                      </div>
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default BlogsCarousel;