import React, { Suspense, lazy, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const BlogDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [BlogComponent, setBlogComponent] = useState<React.LazyExoticComponent<React.FC> | null>(null);
  const [componentExists, setComponentExists] = useState(true);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        const component = await import(`./${id}`);
        setBlogComponent(lazy(() => Promise.resolve({ default: component.default })));
      } catch (error) {
        console.error(`Blog component not found for id: ${id}`, error);
        setComponentExists(false);
      }
    };

    loadComponent();
  }, [id]);

  return (
    <div className="min-h-screen bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        {componentExists ? (
          BlogComponent ? (
            <Suspense fallback={<div>Loading...</div>}>
              <BlogComponent />
            </Suspense>
          ) : (
            <div>Loading...</div>
          )
        ) : (
          <div>
            <h1 className="text-3xl font-bold mb-4">Blog not found</h1>
            <p>Sorry, the blog you are looking for does not exist.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogDetail;