import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center p-6">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-4xl w-full">
                <div className="p-8">
                    <h1 className="text-3xl font-bold mb-6 text-center text-darkGreen">Privacy Policy</h1>
                    <p className="text-gray-500 text-sm mb-4 text-center"><strong>Effective Date:</strong> 16th September 2024</p>
                    <p className="text-gray-700 leading-relaxed mb-6">
                        At <strong>YourNidra</strong> (owned and operated by <strong>VitalEdge Health Private Limited</strong>), we value your
                        privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, and share
                        your personal information when you use our website <strong>yournidra.com</strong> ("Website").
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">1. Information We Collect</h2>
                    <p className="text-gray-700 mb-4">
                        We collect personal information that you voluntarily provide to us when you:
                    </p>
                    <ul className="list-disc ml-8 mb-4 text-gray-700">
                        <li>Create an account on the Website.</li>
                        <li>Use our services or take assessments.</li>
                        <li>Contact us through our support channels.</li>
                    </ul>
                    <p className="text-gray-700 mb-4">
                        The types of information we may collect include:
                    </p>
                    <ul className="list-disc ml-8 mb-6 text-gray-700">
                        <li><strong>Personal Identifiers:</strong> Name, email address, phone number, date of birth, gender, etc.</li>
                        <li><strong>Health Information:</strong> Information related to your sleep patterns or assessments.</li>
                        <li><strong>Usage Data:</strong> Information about how you interact with the Website, including your IP address, browser type, and pages viewed.</li>
                    </ul>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">2. Use of Your Information</h2>
                    <p className="text-gray-700 mb-6">
                        We use the personal information collected for the following purposes:
                    </p>
                    <ul className="list-disc ml-8 mb-6 text-gray-700">
                        <li>To provide you with our services and improve the Website’s functionality.</li>
                        <li>To process your assessments and generate sleep-related reports.</li>
                        <li>To communicate with you, including sending reminders, service updates, and promotional materials.</li>
                        <li>To ensure compliance with legal obligations and protect our rights.</li>
                    </ul>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">3. Cookies and Tracking Technologies</h2>
                    <p className="text-gray-700 mb-6">
                        We may use cookies and similar tracking technologies to enhance your experience on our Website. Cookies allow us to:
                    </p>
                    <ul className="list-disc ml-8 mb-6 text-gray-700">
                        <li>Track and analyze user behavior to improve the Website.</li>
                        <li>Remember your preferences and login details.</li>
                        <li>Provide personalized content.</li>
                    </ul>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">4. Sharing Your Information</h2>
                    <p className="text-gray-700 mb-6">
                        We do not sell or share your personal information with third parties except under the following conditions:
                    </p>
                    <ul className="list-disc ml-8 mb-6 text-gray-700">
                        <li><strong>Service Providers:</strong> We may share your data with trusted third-party service providers to help us deliver our services.</li>
                        <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring company.</li>
                    </ul>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">5. Data Security</h2>
                    <p className="text-gray-700 mb-6">
                        We take appropriate technical and organizational measures to protect your personal information from unauthorized access,
                        disclosure, alteration, or destruction. While we strive to protect your personal data, no method of transmission over
                        the internet or electronic storage is completely secure. Therefore, we cannot guarantee its absolute security.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">6. Data Retention</h2>
                    <p className="text-gray-700 mb-6">
                        We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy
                        Policy, unless a longer retention period is required or permitted by law. After the retention period, we will delete or
                        anonymize your data.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">7. Your Rights</h2>
                    <ul className="list-disc ml-8 mb-6 text-gray-700">
                        <li><strong>Right to Access:</strong> You have the right to access the personal information we hold about you.</li>
                        <li><strong>Right to Rectification:</strong> You have the right to request that we correct any inaccurate or incomplete personal data.</li>
                        <li><strong>Right to Erasure:</strong> You have the right to request that we delete your personal data, subject to certain legal obligations.</li>
                        <li><strong>Right to Object:</strong> You have the right to object to the processing of your data in certain circumstances, including for direct marketing purposes.</li>
                    </ul>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">8. Third-Party Links</h2>
                    <p className="text-gray-700 mb-6">
                        Our Website may contain links to third-party websites or services. We are not responsible for the privacy practices of
                        these third-party websites. We encourage you to review the privacy policies of any third-party websites you visit.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">9. Changes to this Privacy Policy</h2>
                    <p className="text-gray-700 mb-6">
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page, along with the updated
                        "Effective Date." Your continued use of our Website following any changes signifies your acceptance of the revised
                        Privacy Policy.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">10. Contact Us</h2>
                    <p className="text-gray-700">
                        If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please
                        contact us at:
                    </p>
                    <p className="text-gray-700 mt-4">
                        <strong>VitalEdge Health Private Limited</strong> <br />
                        Email: <a href="mailto:hello@yournidra.com" className="text-blue-500">hello@yournidra.com</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;