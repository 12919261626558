// src/api/axiosInstance.ts
import axios from 'axios';
import API_URL from '../config/config';

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 5000,
    headers: { 'Content-Type': 'application/json' }
});

// Optionally, add request interceptors if needed
axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;