import React from 'react';

const About: React.FC = () => (
    <section id="about" className="py-36 bg-white">
        <div className="container mx-auto px-4">
            {/* Reduced the margin between the heading and paragraph */}
            <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">
                Understanding Sleep Apnea is Important
            </h2>
            <p className="text-gray-700 text-center mb-8">
                Sleep apnea is a common but often undiagnosed condition that affects millions of people worldwide.
            </p>

            {/* Increased the card length and added images */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow min-h-[400px] lg:min-h-[500px]"> {/* Larger height on large screens */}
                    <img src="/images/carousel-2.jpeg" alt="What is Sleep Apnea?" className="w-full h-48 object-cover mb-4 rounded-lg" />
                    <h3 className="text-xl font-semibold mb-4">What is Sleep Apnea?</h3>
                    <p className="text-gray-600">
                        In India, it is estimated that over 80% of moderate to severe sleep apnea cases remain undiagnosed.
                    </p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow min-h-[400px] lg:min-h-[500px]"> {/* Larger height on large screens */}
                    <img src="/images/carousel-3.jpeg" alt="Who is at Risk?" className="w-full h-48 object-cover mb-4 rounded-lg" />
                    <h3 className="text-xl font-semibold mb-4">Who is at Risk?</h3>
                    <p className="text-gray-600">
                        Studies suggest that around 1 in 5 adults in urban India are at risk of obstructive sleep apnea.
                    </p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow min-h-[400px] lg:min-h-[500px]"> {/* Larger height on large screens */}
                    <img src="/images/carousel-4.jpeg" alt="Why Diagnosis Matters" className="w-full h-48 object-cover mb-4 rounded-lg" />
                    <h3 className="text-xl font-semibold mb-4">Why Diagnosis Matters</h3>
                    <p className="text-gray-600">
                        Early diagnosis and treatment can reduce healthcare costs and improve patient quality of life.
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default About;