import React, { useState } from 'react';
import axios from 'axios';
import API_URL from '../../../config/config';
import CompanyModal from './CompanyModal'; // Import the modal component

interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gender: string;
  phoneNumber: string;
}

const CreateMultipleUsers: React.FC = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [users, setUsers] = useState<IUser[]>([
    {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      gender: '',
      phoneNumber: '',
    },
  ]);
  const [isCompanyRegistered, setIsCompanyRegistered] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);  // State to control modal visibility

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const newUsers = [...users];
    newUsers[index] = { ...newUsers[index], [name]: value };
    setUsers(newUsers);
  };

  const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
  };

  const handleCheckAndAddCompany = async () => {
    try {
      const response = await axios.post(`${API_URL}/company/check`, { companyName });

      if (response.data.company) {
        alert('Company already exists. Details retrieved.');
        setCompanyId(response.data.company._id);
        setIsCompanyRegistered(true);
      } else {
        // Company does not exist, show the modal to collect new company information
        setIsModalVisible(true);
      }
    } catch (error) {
      console.error('Error checking or adding company:', error);
      alert('Error checking or adding company');
    }
  };

  const handleAddNewCompany = async (companyData: {
    companyName: string;
    companyEmail: string;
    phoneNumber: string;
    address: string;
    website: string;
  }) => {
    // Close modal and register company
    setIsModalVisible(false);
    try {
      const registerResponse = await axios.post(`${API_URL}/company/register`, companyData);
      setCompanyId(registerResponse.data.company._id);
      alert('Company registered successfully.');
      setIsCompanyRegistered(true);
    } catch (error) {
      console.error('Error registering company:', error);
      alert('Error registering company');
    }
  };

  const addUserForm = () => {
    setUsers([
      ...users,
      { firstName: '', lastName: '', email: '', password: '', gender: '', phoneNumber: '' },
    ]);
  };

  const handleRemoveUser = (index: number) => {
    const newUsers = users.filter((_, i) => i !== index);
    setUsers(newUsers);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      // Ensure companyId is available
      if (!companyId) {
        alert('Please check or add company details before submitting.');
        return;
      }

      // Send companyId and users together in the request
      const response = await axios.post(`${API_URL}/user/create-users`, {
        companyId,
        users,
      });
      alert('Users created successfully!');
      setCompanyName('');
      setCompanyId(null);
      setIsCompanyRegistered(false);
      setUsers([
        {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          gender: '',
          phoneNumber: '',
        },
      ]);
    } catch (error) {
      console.error('Error creating users:', error);
      alert('Error creating users');
    }
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-6">Create Multiple Users</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-6 flex items-end">
          <div className="w-full">
            <label className="block text-sm font-medium mb-2">Company Name:</label>
            <input
              type="text"
              name="companyName"
              value={companyName}
              onChange={handleCompanyNameChange}
              className="border border-gray-300 rounded-lg p-2 w-full"
              placeholder="Enter Company Name"
              required
              disabled={isCompanyRegistered}
            />
          </div>
          <button
            type="button"
            onClick={handleCheckAndAddCompany}
            className="ml-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Check
          </button>
        </div>

        {/* Modal Popup */}
        <CompanyModal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSubmit={handleAddNewCompany}
        />

        {users.map((user, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg p-6 mb-4">
            <h2 className="text-xl font-semibold mb-4">User {index + 1}</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-2">First Name:</label>
                <input
                  type="text"
                  name="firstName"
                  value={user.firstName}
                  onChange={(e) => handleInputChange(index, e)}
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Last Name:</label>
                <input
                  type="text"
                  name="lastName"
                  value={user.lastName}
                  onChange={(e) => handleInputChange(index, e)}
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Email:</label>
                <input
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={(e) => handleInputChange(index, e)}
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Password:</label>
                <input
                  type="text"
                  name="password"
                  value={user.password}
                  onChange={(e) => handleInputChange(index, e)}
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Gender:</label>
                <select
                  name="gender"
                  value={user.gender}
                  onChange={(e) => handleInputChange(index, e)}
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Phone Number:</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={user.phoneNumber}
                  onChange={(e) => handleInputChange(index, e)}
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  required
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => handleRemoveUser(index)}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg"
            >
              Remove User
            </button>
          </div>
        ))}

        <button
          type="button"
          onClick={addUserForm}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          Add Another User
        </button>
        <button type="submit" className="ml-4 bg-green-500 text-white px-4 py-2 rounded-lg">
          Submit Users
        </button>
      </form>
    </div>
  );
};

export default CreateMultipleUsers;