import React, { useState } from 'react';

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (companyData: {
    companyName: string;
    companyEmail: string;
    phoneNumber: string;
    address: string;
    website: string;
  }) => void;
}

const CompanyModal: React.FC<ModalProps> = ({ isVisible, onClose, onSubmit }) => {
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');

  const handleSubmit = () => {
    if (!companyEmail) {
      alert('Please share the email ID.');
      return;
    }

    onSubmit({ companyName, companyEmail, phoneNumber, address, website });
    onClose(); // Close the modal after submitting
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
        <h2 className="text-xl font-bold mb-4">Add Company Details</h2>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Company Name</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-full"
            placeholder="Enter Company Name"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Company Email</label>
          <input
            type="email"
            value={companyEmail}
            onChange={(e) => setCompanyEmail(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-full"
            placeholder="Enter Company Email"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Phone Number (optional)</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-full"
            placeholder="Enter Company Phone Number"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Address (optional)</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-full"
            placeholder="Enter Company Address"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Website (optional)</label>
          <input
            type="text"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-full"
            placeholder="Enter Company Website"
          />
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyModal;