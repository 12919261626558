// src/api/consultationApi.ts
import axiosInstance from './axiosInstance';

export const getUserConsultations = (userId: string, token: string) => {
    return axiosInstance.get(`/consultation/user/all/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        return response.data.consultationData;
      });
};

export const scheduleConsultation = (userId: string, date: string, time: string, token: string) => {
  return axiosInstance.post('/consultation/schedule', {
    userId,
    date,
    time,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const cancelConsultationApi = (consultationId: string, token: string) => {
    return axiosInstance.delete(`/consultation/${consultationId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

//   export const cancelConsultationApi = (consultationId: string, token: string) => {
//     return axiosInstance.patch(`/consultation/${consultationId}/status`, {
//       status: 'cancelled',
//     }, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//   };