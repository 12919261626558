import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('IsLoggedIn');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    localStorage.removeItem('mobile');
    localStorage.removeItem('gender');

    // Redirect to login page
    navigate('/user/login');
  }, [navigate]);

  return null; // No need to render anything
};

export default Logout;