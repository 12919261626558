import React from 'react';
import { Link } from 'react-router-dom';

const Symptoms: React.FC = () => (
  <section id="symptoms" className="bg-gray-50 py-36"> 
    <div className="container mx-auto px-6 text-center">
      <h2 className="text-4xl font-bold text-gray-900 mb-16"> 
        Do these symptoms seem familiar?
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-16"> 
        <div className="hover:shadow-lg transition-shadow">
          <Link to="/blog/CommonSymptomsOfSleepApnea">
            <img src="/images/daytime-sleeping.png" alt="Excessive Daytime Sleepiness" className="mx-auto mb-6 h-56" /> 
            <p className="text-2xl text-darkGreen font-semibold"> 
              Excessive Daytime Sleepiness
            </p>
          </Link>
        </div>
        <div className="hover:shadow-lg transition-shadow">
          <Link to="/blog/CommonSymptomsOfSleepApnea">
            <img src="/images/snoring.png" alt="Loud Snoring" className="mx-auto mb-6 h-56" /> 
            <p className="text-2xl text-darkGreen font-semibold"> 
              Loud Snoring
            </p>
          </Link>
        </div>
        <div className="hover:shadow-lg transition-shadow">
          <Link to="/blog/CommonSymptomsOfSleepApnea">
            <img src="/images/gasping.png" alt="Gasping or Choking during Sleep" className="mx-auto mb-6 h-56" /> 
            <p className="text-2xl text-darkGreen font-semibold"> 
              Gasping or Choking during Sleep
            </p>
          </Link>
        </div>
        <div className="hover:shadow-lg transition-shadow">
          <Link to="/blog/CommonSymptomsOfSleepApnea">
            <img src="/images/headaches.png" alt="Morning Headaches" className="mx-auto mb-6 h-56" /> 
            <p className="text-2xl text-darkGreen font-semibold"> 
              Morning Headaches
            </p>
          </Link>
        </div>
      </div>
      <Link to="/blog/CommonSymptomsOfSleepApnea" className="bg-darkGreen text-white py-4 px-10 mt-12 rounded-full inline-block transition">
        Know More about Symptoms
      </Link>
    </div>
  </section>
);

export default Symptoms;