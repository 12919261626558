import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../../config/config';

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>(); // Extract token from URL
  const navigate = useNavigate(); // For navigation after success
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  // Scroll to top whenever the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  useEffect(() => {
    // Verify the token with the backend to make sure it's valid
    const verifyToken = async () => {
      try {
        await axios.get(`${API_URL}/user/verify-reset-token/${token}`);
      } catch (err) {
        setModalMessage('The token has expired or is invalid.');
        setIsModalOpen(true); // Open modal to show token error
      }
    };
    verifyToken();
  }, [token]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Reset error state

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/user/reset-password/${token}`, {
        password,
      });
      setMessage(response.data.message);
      setModalMessage('Password has been reset successfully.');
      setIsModalOpen(true); // Open modal on success
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || 'Failed to reset password');
      } else {
        setError('Failed to reset password');
      }
    }
  };

  const handleOkClick = () => {
    setIsModalOpen(false);
    navigate('/user/login'); // Redirect to login page on modal close
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8">
        <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 mx-auto max-w-lg">
          <h2 className="text-center text-4xl font-bold text-darkGreen mb-4">
            Reset Password
          </h2>
          <p className="text-center text-lg text-gray-600 mb-6">
            Enter your new password below.
          </p>
          {error && <div className="text-red-600 text-center mt-4">{error}</div>}
          {message && <div className="text-green-600 text-center mt-4">{message}</div>}
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm space-y-4">
              <div>
                <label htmlFor="password" className="sr-only">New Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="confirm-password" className="sr-only">Confirm Password</label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  required
                  className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg"
              >
                Reset Password
              </button>
            </div>
          </form>
          <div className="text-sm text-center mt-6">
            <a href="/user/login" className="font-medium text-blue-600 hover:text-blue-500">
              Back to Login
            </a>
          </div>
        </div>
      </div>
      
      {/* Modal Popup */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg max-w-sm w-full">
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">
                {modalMessage === 'Password has been reset successfully.' ? 'Success' : 'Error'}
              </h3>
              <p className="text-gray-700 mb-6">
                {modalMessage}
              </p>
              <button
                onClick={handleOkClick}
                className="w-full bg-darkGreen text-white py-2 px-4 rounded-md transition duration-300"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;