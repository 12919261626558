// src/api/authApi.ts
import axiosInstance from './axiosInstance';
import { LoginFormData, RegisterFormData } from '../features/auth/types/authTypes';

export const login = (formData: LoginFormData) => {
    return axiosInstance.post('/user/login', formData);
};

export const register = (formData: RegisterFormData) => {
    return axiosInstance.post('/user/register', formData);
};

export const forgotPassword = (email: string) => {
    return axiosInstance.post('/user/forgot-password', { email });
};

export const resetPassword = (token: string, password: string) => {
    return axiosInstance.post(`/user/reset-password/${token}`, { password });
};
