import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Hero: React.FC = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleImageClick = () => {
        navigate('/quiz');
    };

    return (
        <section
            className={`py-24 sm:py-32 md:py-40 lg:py-48 mt-16 transition-all ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'} duration-1000 ease-out`}
            onClick={handleImageClick}
        >
            <div className="container mx-auto text-center px-4">
                <h1 className={`text-3xl sm:text-4xl md:text-5xl font-bold mb-4 text-gray-900 transition-transform duration-1000 ${isVisible ? 'scale-100' : 'scale-95'}`}>
                    Struggling with Sleep? <span className="text-darkGreen">It could be Sleep Apnea</span>
                </h1>
                <p className={`text-base sm:text-lg md:text-xl text-gray-600 mb-8 md:mb-12 py-2 sm:py-4 leading-relaxed transition-opacity duration-1000 delay-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    Discover if you're at risk and get the help you need.
                </p>
                <a href="/quiz" className="bg-darkGreen text-white py-3 sm:py-4 px-6 sm:px-8 rounded text-base sm:text-lg md:text-xl shadow-lg transition-all duration-500 ease-in-out transform hover:scale-105" style={{ marginTop: '2rem' }}>
                    Take Our Free 2 min Quiz
                </a>
            </div>
        </section>
    );
};

export default Hero;