// src/api/dashboardApi.ts
import axiosInstance from './axiosInstance';
import { UserData } from '../features/user/dashboard/types/dashboardTypes';

export const getQuizData = (userId: string, token: string) => {
  return axiosInstance.get(`/quiz/user/all/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    return response.data.quizData;
  });
};

export const getSleepStudyData = (userId: string, token: string) => {
  return axiosInstance.get(`/sleep-study/user/all/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    return response.data.sleepStudyData;
  });
};

export const getConsultationData = (userId: string, token: string) => {
    return axiosInstance.get(`/consultation/user/all/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      return response.data.consultationData;
    });
  };

export const updateUser = (userId: string, userData: UserData, token: string) => {
  return axiosInstance.put(`/user/update/${userId}`, userData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};