import React from 'react';

const TermsOfUse: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-4xl w-full">
                <div className="p-8">
                    <h1 className="text-3xl font-bold mb-6 text-center text-darkGreen">Terms and Conditions</h1>
                    <p className="text-gray-500 text-sm mb-4 text-center"><strong>Effective Date:</strong> 16th September 2024</p>
                    <p className="text-gray-700 leading-relaxed mb-6">
                        Welcome to <strong>YourNidra</strong>, owned and operated by <strong>VitalEdge Health Private Limited</strong> (hereinafter referred to as "Company," "we," or "us"). By accessing or using our website located at
                        <strong> yournidra.com</strong> ("Website") or any related services, you agree to comply with and be bound by the following terms and conditions ("Terms").
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">1. Acceptance of Terms</h2>
                    <p className="text-gray-700 mb-6">
                        By accessing and using the Website, you affirm that you are at least 18 years of age or using the Website under the supervision of a parent or guardian. By using our services, you agree to these Terms and any applicable local laws.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">2. Use of Website</h2>
                    <p className="text-gray-700 mb-6">
                        The content provided on this Website, including text, images, and other materials, is for general informational purposes only. The services offered, including any health-related services or assessments, are not a substitute for professional medical advice, diagnosis, or treatment. Please consult a healthcare professional for any health concerns.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">3. Account Registration</h2>
                    <p className="text-gray-700 mb-6">
                        To access certain features of the Website, you may be required to register and create an account. You are responsible for maintaining the confidentiality of your account details and any activities that occur under your account. We reserve the right to suspend or terminate accounts for violations of these Terms.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">4. Privacy Policy</h2>
                    <p className="text-gray-700 mb-6">
                        Your use of the Website is subject to our <a href="/privacy-policy" className="text-blue-500">Privacy Policy</a>, which governs how we collect, use, and share your personal data. Please review the Privacy Policy for detailed information.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">5. Intellectual Property</h2>
                    <p className="text-gray-700 mb-6">
                        All content and materials provided on the Website, including but not limited to text, images, logos, and software, are the intellectual property of <strong>VitalEdge Health Private Limited</strong> or its content suppliers. You may not reproduce, distribute, or otherwise use any content without explicit written permission from us.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">6. Service Availability</h2>
                    <p className="text-gray-700 mb-6">
                        We strive to keep our services available at all times, but we cannot guarantee uninterrupted or error-free access. We may perform scheduled maintenance, upgrades, or emergency repairs without prior notice.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">7. Disclaimer of Warranties</h2>
                    <p className="text-gray-700 mb-6">
                        The Website and all content and services are provided on an "as is" and "as available" basis. <strong>VitalEdge Health Private Limited</strong> makes no warranties, express or implied, regarding the Website, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">8. Limitation of Liability</h2>
                    <p className="text-gray-700 mb-6">
                        Under no circumstances shall <strong>VitalEdge Health Private Limited</strong> or its employees, directors, or agents be liable for any direct, indirect, incidental, or consequential damages arising from your use of the Website or services provided, including but not limited to damages for loss of profits, goodwill, or data.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">9. Changes to Terms</h2>
                    <p className="text-gray-700 mb-6">
                        We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on the Website. Continued use of the Website after changes are posted constitutes your acceptance of the revised Terms.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">10. Governing Law</h2>
                    <p className="text-gray-700 mb-6">
                        These Terms and any disputes arising from them are governed by and construed in accordance with the laws of India. Any disputes arising in relation to the Website will be resolved in courts located in India.
                    </p>

                    <h2 className="text-2xl font-bold mt-6 mb-4 text-darkGreen">11. Contact Us</h2>
                    <p className="text-gray-700">
                        If you have any questions about these Terms, please contact us at: <br />
                        <strong>VitalEdge Health Private Limited</strong> <br />
                        Email: <a href="mailto:hello@yournidra.com" className="text-blue-500">hello@yournidra.com</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;