import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();
    const isLoggedIn = localStorage.getItem('IsLoggedIn');

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    // Check if we're in a user dashboard route
    const isDashboardRoute = location.pathname.startsWith('/user');

    // Effect to add/remove shadow based on scroll position
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`w-full fixed top-0 py-4 z-50 transition-shadow duration-300 ${isScrolled ? 'shadow-md bg-white' : 'shadow-md bg-white'}`}>
            <div className="container max-w-screen-xl mx-auto px-4 flex justify-between items-center">
                <Link to="/" className="flex items-center">
                    <img src="/logo.png" alt="YourNidra Logo" className="h-10" />
                </Link>

                <div className="hidden lg:flex space-x-8 items-center">
                    {!isDashboardRoute ? (
                        <>
                            <a href="/#symptoms" className="text-gray-700 hover:text-darkGreen">About OSA</a>
                            <a href="/#testimonials" className="text-gray-700 hover:text-darkGreen">Testimonials</a>
                            <a href="/#footer" className="text-gray-700 hover:text-darkGreen">Contact</a>
                            <Link to="/blogs" className="text-gray-700 hover:text-darkGreen">Blogs</Link>

                            {isLoggedIn ? (
                                <Link to="/user/dashboard" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow">
                                    My Account
                                </Link>
                            ) : (
                                <Link to="/user/login" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow">
                                    Login
                                </Link>
                            )}
                        </>
                    ) : (
                        <>
                            {/* Desktop view sidebar options (if needed) */}
                        </>
                    )}
                </div>

                {/* Hamburger menu for mobile */}
                <div className="lg:hidden">
                    <button onClick={toggleMenu} className="text-gray-800">
                        {isOpen ? <FaTimes className="w-6 h-6" /> : <FaBars className="w-6 h-6" />}
                    </button>
                </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
                <div className="lg:hidden flex flex-col space-y-4 mt-4 text-center bg-white p-4 shadow-md">
                    {isDashboardRoute ? (
                        <>
                            {/* Sidebar Links for Dashboard Routes */}
                            <Link to="/user/dashboard" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>Overview</Link>
                            <Link to="/user/profile" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>Profile</Link>
                            <Link to="/user/questionnaire" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>Self Assessment</Link>
                            <Link to="/user/sleep-study" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>Sleep Study</Link>
                            <Link to="/user/consult" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>Consultation</Link>
                            <Link to="/user/cpap" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>Devices</Link>
                            <Link to="/user/referral" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>Referrals</Link>
                            <Link to="/user/faq" className="block text-gray-600 hover:text-indigo-900" onClick={closeMenu}>FAQs</Link>
                            <Link to="/user/logout" className="block text-red-600 hover:text-red-900" onClick={closeMenu}>Logout</Link>
                        </>
                    ) : (
                        <>
                            {/* Default Navbar Links */}
                            <a href="/#symptoms" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>About OSA</a>
                            <a href="/#testimonials" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Testimonials</a>
                            <a href="/#footer" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Contact</a>
                            <Link to="/blogs" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Blogs</Link>
                            {isLoggedIn ? (
                                <Link to="/user/dashboard" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow" onClick={closeMenu}>
                                    My Account
                                </Link>
                            ) : (
                                <Link to="/user/login" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow" onClick={closeMenu}>
                                    Login
                                </Link>
                            )}
                        </>
                    )}
                </div>
            )}
        </nav>
    );
};

export default Navbar;