// src/features/dashboard/services/dashboardService.ts
import { getConsultationData, getQuizData, getSleepStudyData, updateUser } from '../../../../api/dashboardApi';
import { ProgressData, UserData } from '../types/dashboardTypes';
import { format } from 'date-fns';

export const fetchProgressData = async (userId: string, token: string): Promise<ProgressData[]> => {
    const progressData: ProgressData[] = [
        { title: 'Self Assessment', status: 'Not Taken', date: 'Pending', linkTo: '/user/questionnaire' },
        { title: 'Sleep Study', status: 'Not Scheduled', date: 'Pending', linkTo: '/user/sleep-study' },
        { title: 'Doctor Consultation', status: 'Not Scheduled', date: 'Pending', linkTo: '/user/consult' },
        { title: 'Device Purchase', status: 'Not Purchased', date: 'Pending', linkTo: '/user/cpap' },
    ];

    try {
        // Use Promise.allSettled to handle each API request independently
        const [quizResult, sleepStudyResult, consultationResult] = await Promise.allSettled([
            getQuizData(userId, token),
            getSleepStudyData(userId, token),
            getConsultationData(userId, token),
        ]);

        // Handle quiz data
        if (quizResult.status === 'fulfilled' && quizResult.value?.length > 0) {
            const latestQuiz = quizResult.value[0];
            if (latestQuiz?.createdAt) {
                progressData[0] = {
                    title: 'Self Assessment',
                    status: 'Completed',
                    date: format(new Date(latestQuiz.createdAt), 'dd MMM yyyy'),
                    linkTo: '/user/questionnaire',
                };
            }
        } else {
            console.warn('No quiz data found for user or failed to fetch quiz data.');
        }

        // Handle sleep study data
        if (sleepStudyResult.status === 'fulfilled' && sleepStudyResult.value?.length > 0) {
            const latestSleepStudy = sleepStudyResult.value[0];
            if (latestSleepStudy?.createdAt) {
                progressData[1] = {
                    title: 'Sleep Study',
                    status: latestSleepStudy.status,
                    date: format(new Date(latestSleepStudy.createdAt), 'dd MMM yyyy'),
                    linkTo: '/user/sleep-study',
                };
            }
        } else {
            console.warn('No sleep study data found for user or failed to fetch sleep study data.');
        }

        // Handle consultation data
        if (consultationResult.status === 'fulfilled' && consultationResult.value?.length > 0) {
            const latestConsultation = consultationResult.value[0];
            if (latestConsultation?.createdAt) {
                progressData[2] = {
                    title: 'Doctor Consultation',
                    status: latestConsultation.status,
                    date: format(new Date(latestConsultation.createdAt), 'dd MMM yyyy'),
                    linkTo: '/user/consult',
                };
            }
        } else {
            console.warn('No consultation data found for user or failed to fetch consultation data.');
        }

    } catch (error) {
        console.error('Failed to fetch progress data:', error);
        throw new Error('Failed to fetch progress data');
    }
    return progressData;
};


export const saveUserData = async (userId: string, userData: UserData, token: string) => {
    await updateUser(userId, userData, token);
};