// src/features/consultations/services/consultationService.ts
import { cancelConsultationApi, getUserConsultations, scheduleConsultation } from '../../../../api/consultationApi';
import { ConsultationData } from '../types/consultationTypes';

export const fetchConsultations = async (userId: string, token: string): Promise<ConsultationData[]> => {
    const response = await getUserConsultations(userId, token);
    return response || [];
};

export const createConsultation = async (userId: string, date: string, time: string, token: string) => {
    await scheduleConsultation(userId, date, time, token);
};

export const cancelConsultation = async (consultationId: string, token: string) => {
    await cancelConsultationApi(consultationId, token);
};