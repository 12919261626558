import React from 'react';
import Sidebar from '../../Sidebar';

const Device: React.FC = () => {
  return (
    <div className="flex h-screen bg-gradient-to-br from-blue-50 to-indigo-100 font-sans">
      <Sidebar />
      <div className="flex-1 flex items-center justify-center overflow-y-auto p-8">
        <h1 className="text-5xl font-bold text-gray-700">Coming Soon</h1>
      </div>
    </div>
  );
};

export default Device;