// src/features/blogs/components/BlogCard.tsx
import React from 'react';
import { BlogCardProps } from '../types/blogTypes';

const BlogCard: React.FC<BlogCardProps> = ({ title, image, link }) => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden">
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="p-4">
      <h2 className="text-xl font-bold mb-2">{title}</h2>
      <a href={link} className="text-blue-600 hover:underline">Read More</a>
    </div>
  </div>
);

export default BlogCard;