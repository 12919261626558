import React from 'react';
import { Link } from 'react-router-dom';

interface ProgressCardProps {
  title: string;
  status: string;
  date: string;
  linkTo: string;
  className?: string;
}

const ProgressCard: React.FC<ProgressCardProps> = ({ title, status, date, linkTo }) => {
  // Define styles based on the status
  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'bg-green-500 text-white'; // Green tick for completed
      case 'Scheduled':
        return 'bg-yellow-500 text-white'; // Yellow for pending
      default:
        return 'bg-gray-300 text-gray-700'; // Grey for not started
    }
  };

  // Define left border color based on the status
  const getCardSideColor = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'border-green-500'; // Green left border for completed
      case 'Scheduled':
        return 'border-yellow-500'; // Yellow left border for pending
      default:
        return 'border-gray-300'; // Grey left border for not started
    }
  };

  return (
    <Link to={linkTo} className="block mb-4 lg:mb-8"> {/* Use different margin for mobile and large screens */}
      <div className="relative flex flex-col lg:flex-row items-start w-full lg:w-auto">
        {/* Adjust flex direction based on screen size */}
        {/* Card Content */}
        <div className={`flex items-center bg-white shadow-lg p-4 lg:p-6 rounded-lg flex-1 border-l-4 lg:border-l-8 ${getCardSideColor(status)} w-full`}>
          {/* Adjust padding, shadow size, and border size based on screen size */}
          
          {/* Status Icon */}
          <div className={`h-10 w-10 rounded-full flex items-center justify-center mr-4 lg:mr-6 ${getStatusStyle(status)}`}>
            {status === 'Completed' ? '✔️' : '○'}
          </div>

          {/* Text Content */}
          <div className="flex-1">
            {/* Title */}
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
              <h3 className="text-lg lg:text-xl font-semibold">{title}</h3> {/* Different text size for mobile and desktop */}
              <span className="text-sm lg:text-base text-gray-500 mt-1 lg:mt-0">{date}</span> {/* Date text size and margin */}
            </div>
            
            {/* Status */}
            <p className={`text-sm lg:text-md mt-2 ${status === 'Completed' ? 'text-green-600' : 'text-yellow-600'}`}>
              {status}
            </p> {/* Adjust font size for status based on screen size */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProgressCard;