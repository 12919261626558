// src/api/quizApi.ts
import axiosInstance from './axiosInstance';
import { QuizApiResponse, QuizData } from '../features/user/questionnaire/types/quizTypes';

export const fetchQuizzesData = async (userId: string, token: string): Promise<QuizApiResponse> => {
    return axiosInstance.get(`/quiz/user/all/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then(response => response.data);
};

export const fetchQuizDetailsData = async (quizId: string, token: string): Promise<QuizData> => {
    return axiosInstance.get(`/quiz/${quizId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then(response => response.data.quiz);
};