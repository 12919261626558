// src/components/BlankPage.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BlankPage: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/blogs');
        }, 1000); // Redirect after 1 second

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="flex items-center justify-center h-screen">
            <p className="text-2xl">Redirecting...</p>
        </div>
    );
};

export default BlankPage;