// src/features/questionnaire/services/quizService.ts
import { fetchQuizzesData, fetchQuizDetailsData } from '../../../../api/quizApi';
import { QuizApiResponse, QuizData } from '../types/quizTypes';

// Service to load quizzes for a user
export const fetchQuizzes = async (userId: string, token: string): Promise<QuizData[]> => {
    try {
      const response: QuizApiResponse = await fetchQuizzesData(userId, token);  
      // Extract the quizData from the response and return it
      return response.quizData;
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      throw error;
    }
  };

// Service to fetch details of a specific quiz
export const fetchQuizDetails = async (quizId: string, token: string): Promise<QuizData> => {
  try {
    const quiz = await fetchQuizDetailsData(quizId, token);
    return quiz;
  } catch (error) {
    console.error('Error fetching quiz details:', error);
    throw error;
  }
};