// src/features/profile/services/profileService.ts
import { fetchProfileData, updateProfile } from '../../../../api/profileApi';
import { UserProfile } from '../types/profileTypes';

export const loadProfile = async (userId: string, token: string): Promise<UserProfile> => {
  const response = await fetchProfileData(userId, token);
  return response.data.user;
};

export const saveProfile = async (userId: string, profileData: UserProfile, token: string) => {
  await updateProfile(userId, profileData, token);
};