/**
 * Capitalizes the first letter of each word in a string.
 * 
 * @param str - The input string.
 * @returns A string with the first letter of each word capitalized, or an empty string if input is invalid.
 */
export const capitalizeWords = (str: string): string => {
    // Check for invalid input (null, undefined, or non-string values)
    if (typeof str !== 'string' || !str) {
        console.error('Invalid input: Input must be a non-empty string.');
        return '';
    }

    // Check if the string contains multiple words by looking for spaces
    if (!str.includes(' ')) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    // If multiple words are present, split and capitalize each word
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};