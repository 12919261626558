import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../config/config';
import { useNavigate } from 'react-router-dom';
import { differenceInYears } from 'date-fns';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

const Quiz: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [currentSection, setCurrentSection] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [showFailure, setShowFailure] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [formData, setFormData] = useState({
        snore: '',
        tired: '',
        observed: '',
        bp: '',
        gender: '',
        collar: '',
        age: '',
        height: '',
        weight: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        user: '', // To hold user ID when logged in
    });

    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn') === 'true'; // Check if user is logged in
    const userId = localStorage.getItem('id'); // Get user ID from localStorage
    const userGender = localStorage.getItem('gender'); // Get gender from localStorage if logged in
    const userDOB = localStorage.getItem('dateOfBirth'); // Get date of birth from localStorage if logged in
    const navigate = useNavigate();

    const [heightFeet, setHeightFeet] = useState('');
    const [heightInches, setHeightInches] = useState('');

    useEffect(() => {
        if (isUserLoggedIn) {
            // If logged in, automatically populate gender and calculate age from DOB
            if (userGender) {
                setFormData((prevData) => ({
                    ...prevData,
                    gender: userGender,
                }));
            }

            if (userDOB) {
                const calculatedAge = differenceInYears(new Date(), new Date(userDOB));
                setFormData((prevData) => ({
                    ...prevData,
                    age: calculatedAge.toString(),
                }));
            }
        }
    }, [isUserLoggedIn, userGender, userDOB]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'heightFeet') {
            setHeightFeet(value);
        } else {
            setHeightInches(value);
        }
    };

    const handleNext = () => {
        if (formData.snore && formData.tired && formData.observed && formData.bp) {
            setCurrentSection((prev) => Math.min(prev + 1, 2));
        } else {
            alert("Please answer all questions.");
        }
    };

    const handlePrevious = () => {
        setCurrentSection((prev) => Math.max(prev - 1, 1));
    };

    const handleSubmit = () => {
        if (formData.collar && formData.age && heightFeet && heightInches && formData.weight) {
            const heightCm = (parseInt(heightFeet) * 30.48) + (parseInt(heightInches) * 2.54);
            setFormData({ ...formData, height: heightCm.toFixed(2) });
            setShowPopup(true);
            // Prevent scrolling
            document.body.style.overflow = 'hidden';
        } else {
            alert("Please fill in all fields.");
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        // Restore scrolling
        document.body.style.overflow = 'auto';
    };

    const handleShareResult = async () => {
        setIsLoading(true);

        let requestData = { ...formData };

        try {
            // Validate required fields for non-logged-in users
            if (!isUserLoggedIn) {
                if (!formData.firstName || !formData.lastName || !formData.email || !formData.mobile) {
                    toast.error('Please fill in all contact details');
                    setIsLoading(false);
                    return;
                }
            }

            if (isUserLoggedIn) {
                requestData.user = userId!;
                console.log('Logged in user data:', requestData);
            } else {
                // Check if the user exists
                console.log('Checking if user exists:', { email: formData.email, mobile: formData.mobile });
                const checkUserResponse = await fetch(`${API_URL}/user/check-user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: formData.email,
                        mobile: formData.mobile,
                    }),
                });

                const responseData = await checkUserResponse.json();
                console.log('Check user response:', responseData);

                if (!checkUserResponse.ok) {
                    if (checkUserResponse.status === 404) {
                        // User not found, create a new user
                        console.log('Creating new user');
                        const createUserResponse = await fetch(`${API_URL}/user/register`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                firstName: formData.firstName,
                                lastName: formData.lastName,
                                email: formData.email,
                                mobile: formData.mobile,
                                gender: formData.gender,
                                password: 'Welcome@123', // Default password
                            }),
                        });

                        if (createUserResponse.ok) {
                            const newUserData = await createUserResponse.json();
                            requestData.user = newUserData.userId;
                            console.log('New user created:', newUserData);
                        } else {
                            const errorData = await createUserResponse.json();
                            throw new Error(`Error creating new user: ${JSON.stringify(errorData)}`);
                        }
                    } else {
                        throw new Error(`Error checking user: ${responseData.message || checkUserResponse.status}`);
                    }
                } else if (responseData.exists) {
                    requestData.user = responseData.userId;
                    console.log('Existing user found:', responseData);
                }
            }

            // Submit the quiz data
            console.log('Submitting quiz data:', requestData);
            const quizResponse = await fetch(`${API_URL}/quiz/new`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!quizResponse.ok) {
                const errorData = await quizResponse.json();
                throw new Error(`Quiz submission failed: ${JSON.stringify(errorData)}`);
            }

            const quizData = await quizResponse.json();
            console.log('Quiz submission successful:', quizData);

            setIsLoading(false);
            setShowPopup(false);
            setShowSuccessMessage(true);
            document.body.style.overflow = 'hidden';

        } catch (error) {
            console.error('Error in handleShareResult:', error);
            setIsLoading(false);
            setShowPopup(false);
            setShowFailure(true);
            toast.error(error instanceof Error ? error.message : 'An unexpected error occurred');
            document.body.style.overflow = 'hidden';
        }
    };

    const handleOkClick = () => {
        if (isUserLoggedIn) {
            handleShareResult(); // Call the API to save the quiz and redirect to the dashboard
        } else {
            setShowPopup(false); // Close the popup for non-logged-in users
        }
    };

    const handleCloseFailure = () => {
        setShowFailure(false);
        document.body.style.overflow = 'auto';
    };

    const handleSuccessClose = () => {
        setShowSuccessMessage(false);
        document.body.style.overflow = 'auto';
        if (isUserLoggedIn) {
            navigate('/user/dashboard');
        } else {
            navigate('/blogs');
        }
    };

    return (
        <div className="container bg-gray-50 py-36 mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <ToastContainer />
            <div className="bg-white rounded-lg shadow-md p-8 max-w-3xl mx-auto">
                <h2 className="text-2xl font-bold mb-6 text-center">YourNidra Sleep Apnea Self Assessment</h2>
                <p className="text-center mb-6">Welcome to the YourNidra Sleep Apnea Self Assessment. Please answer all questions as accurately as possible.</p>

                {currentSection === 1 && (
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Sleep Patterns</h3>
                        <div className="mb-8">
                            <label className="block mb-2">Do you <b>Snore Loudly</b> (loud enough to be heard through closed doors)?</label>
                            <div className="flex items-center">
                                <input type="radio" id="snore-yes" name="snore" value="yes" className="mr-2" onChange={handleChange} />
                                <label htmlFor="snore-yes" className="mr-4">Yes</label>
                                <input type="radio" id="snore-no" name="snore" value="no" className="mr-2" onChange={handleChange} />
                                <label htmlFor="snore-no">No</label>
                            </div>
                        </div>
                        <div className="mb-8">
                            <label className="block mb-2">Do you often <b>feel tired, fatigued, or sleepy</b> during the day?</label>
                            <div className="flex items-center">
                                <input type="radio" id="tired-yes" name="tired" value="yes" className="mr-2" onChange={handleChange} />
                                <label htmlFor="tired-yes" className="mr-4">Yes</label>
                                <input type="radio" id="tired-no" name="tired" value="no" className="mr-2" onChange={handleChange} />
                                <label htmlFor="tired-no">No</label>
                            </div>
                        </div>
                        <div className="mb-8">
                            <label className="block mb-2">Has anyone Observed you <b>Stop Breathing or Choking/Gasping during your sleep</b>?</label>
                            <div className="flex items-center">
                                <input type="radio" id="observed-yes" name="observed" value="yes" className="mr-2" onChange={handleChange} />
                                <label htmlFor="observed-yes" className="mr-4">Yes</label>
                                <input type="radio" id="observed-no" name="observed" value="no" className="mr-2" onChange={handleChange} />
                                <label htmlFor="observed-no">No</label>
                            </div>
                        </div>
                        <div className="mb-8">
                            <label className="block mb-2">Do you have or have you been treated for <b>high blood pressure</b>?</label>
                            <div className="flex items-center">
                                <input type="radio" id="bp-yes" name="bp" value="yes" className="mr-2" onChange={handleChange} />
                                <label htmlFor="bp-yes" className="mr-4">Yes</label>
                                <input type="radio" id="bp-no" name="bp" value="no" className="mr-2" onChange={handleChange} />
                                <label htmlFor="bp-no">No</label>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button className="bg-darkGreen text-white py-2 px-4 rounded" onClick={handleNext}>Next</button>
                        </div>
                    </div>
                )}

                {currentSection === 2 && (
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Physical Characteristics</h3>
                        {/* Skip asking for gender and age if the user is logged in and data is available */}
                        {!isUserLoggedIn || !userGender ? (
                            <div className="mb-8">
                                <label className="block mb-2">Please share your gender</label>
                                <div className="flex items-center">
                                    <input type="radio" id="male" name="gender" value="male" className="mr-2" onChange={handleChange} />
                                    <label htmlFor="male" className="mr-4">Male</label>
                                    <input type="radio" id="female" name="gender" value="female" className="mr-2" onChange={handleChange} />
                                    <label htmlFor="female">Female</label>
                                </div>
                            </div>
                        ) : null}

                        {!isUserLoggedIn || !userDOB ? (
                            <div className="mb-8">
                                <label className="block mb-2">Please enter your age (in years)</label>
                                <input type="number" name="age" value={formData.age} onChange={handleChange} className="w-full px-3 py-2 border rounded" />
                            </div>
                        ) : null}

                        <div className="mb-8">
                            <label className="block mb-2">Is your shirt collar 16 inches/40 cm or larger?</label>
                            <div className="flex items-center">
                                <input type="radio" id="collar-yes" name="collar" value="yes" className="mr-2" onChange={handleChange} />
                                <label htmlFor="collar-yes" className="mr-4">Yes</label>
                                <input type="radio" id="collar-no" name="collar" value="no" className="mr-2" onChange={handleChange} />
                                <label htmlFor="collar-no">No</label>
                            </div>
                        </div>
                        <div className="mb-8">
                            <label className="block mb-2">Please enter your height</label>
                            <div className="flex items-center mb-2">
                                <input type="number" name="heightFeet" value={heightFeet} onChange={handleHeightChange} placeholder="Feet" className="w-1/2 px-3 py-2 border rounded mr-2" />
                                <input type="number" name="heightInches" value={heightInches} onChange={handleHeightChange} placeholder="Inches" className="w-1/2 px-3 py-2 border rounded" />
                            </div>
                        </div>
                        <div className="mb-8">
                            <label className="block mb-2">Please enter your weight (in kgs)</label>
                            <input type="number" name="weight" value={formData.weight} onChange={handleChange} className="w-full px-3 py-2 border rounded" />
                        </div>
                        <div className="flex justify-between">
                            <button id="previous" className="bg-darkGreen text-white py-2 px-4 rounded" onClick={handlePrevious}>Previous</button>
                            <button id="submit" className="bg-darkGreen text-white py-2 px-4 rounded" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                )}

                {/* Popup for sharing contact details */}
                {showPopup && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-md p-8 mx-auto max-w-lg relative">
                            <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-800" onClick={handleClosePopup}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                            <h2 className="text-2xl font-bold mb-6 text-center">Thank You for taking the Quiz</h2>

                            {/* Show the message differently if the user is logged in */}
                            {isUserLoggedIn ? (
                                <>
                                    <p className="mb-4 text-center">Your self-assessment has been added to your account.</p>
                                    <div className="flex justify-center">
                                        <button className="bg-darkGreen text-white py-2 px-4 rounded" onClick={handleOkClick}>OK</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="mb-4 text-center">Please share your contact details where we can send your results.</p>
                                    <div className="mb-4 flex">
                                        <div className="w-1/2 pr-2">
                                            <label className="block mb-2">First Name</label>
                                            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} className="w-full px-3 py-2 border rounded" />
                                        </div>
                                        <div className="w-1/2 pl-2">
                                            <label className="block mb-2">Last Name</label>
                                            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} className="w-full px-3 py-2 border rounded" />
                                        </div>
                                    </div>
                                    <div className="mb-8">
                                        <label className="block mb-2">Email ID</label>
                                        <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full px-3 py-2 border rounded" />
                                    </div>
                                    <div className="mb-8">
                                        <label className="block mb-2">Mobile Number</label>
                                        <input type="number" name="mobile" value={formData.mobile} onChange={handleChange} className="w-full px-3 py-2 border rounded" />
                                    </div>
                                    <div className="flex justify-end">
                                        <button id="shareResult" className="bg-darkGreen text-white py-2 px-4 rounded" onClick={handleShareResult}>
                                            {isLoading ? "Sending..." : "Share the Result"}
                                        </button>
                                    </div>
                                </>
                            )}

                            {isLoading && (
                                <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
                                    <p className="text-xl font-semibold">Sending...</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {showFailure && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-md p-8 max-w-md mx-auto relative">
                            <button className="absolute top-2 right-2 text-gray-600 hover:text-gray-800" onClick={handleCloseFailure}>
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                            <h2 className="text-2xl font-bold mb-6 text-center">Submission Failed</h2>
                            <p className="mb-4 text-center">There was an error submitting your report. Please try again.</p>
                            <div className="flex justify-center">
                                <button className="bg-darkGreen text-white py-2 px-4 rounded" onClick={handleCloseFailure}>OK</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Success Message Popup */}
                {showSuccessMessage && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-md p-8 max-w-md mx-auto relative">
                            <h2 className="text-2xl font-bold mb-6 text-center">Success!</h2>
                            <p className="mb-6 text-center">
                                Thank you for completing the assessment! Your results will be sent to your email shortly. 
                                Please check your inbox for an email from noreply@yournidra.com
                            </p>
                            <div className="flex justify-center">
                                <button 
                                    className="bg-darkGreen text-white py-2 px-4 rounded"
                                    onClick={handleSuccessClose}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Quiz;