// src/api/profileApi.ts
import axiosInstance from './axiosInstance';
import { UserProfile } from '../features/user/profile/types/profileTypes';

export const fetchProfileData = async (userId: string, token: string) => {
    return axiosInstance.get(`/user/user-details`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const updateProfile = async (userId: string, profileData: UserProfile, token: string) => {
    return axiosInstance.put(`/user/update/${userId}`, profileData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};