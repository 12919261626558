import React from 'react';
import { Link } from 'react-router-dom';

const Diagnosis: React.FC = () => (
    <section id="diagnosis" className="py-36 bg-gray-50">
        <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Get Diagnosed Today</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <Link to="/quiz">
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                        <img src="/images/consultation.jpeg" alt="Initial Consultation" className="w-full h-48 object-cover rounded-t-lg mb-4" />
                        <h3 className="text-xl font-semibold mb-4">Initial Consultation</h3>
                        <p className="text-gray-600">Discuss your symptoms with our specialists to determine the best diagnostic approach.</p>
                    </div>
                </Link>
                <Link to="/quiz">
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                        <img src="/images/sleep-study.jpeg" alt="Home and In-Lab Sleep Studies" className="w-full h-48 object-cover rounded-t-lg mb-4" />
                        <h3 className="text-xl font-semibold mb-4">Home and In-Lab Sleep Studies</h3>
                        <p className="text-gray-600">Choose between a convenient home sleep test or an in-lab study for a detailed assessment.</p>
                    </div>
                </Link>
                <Link to="/quiz">
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                        <img src="/images/treatment-plan.jpeg" alt="Personalized Treatment Plan" className="w-full h-48 object-cover rounded-t-lg mb-4" />
                        <h3 className="text-xl font-semibold mb-4">Personalized Treatment Plan</h3>
                        <p className="text-gray-600">Receive a tailored treatment plan based on your specific needs and lifestyle.</p>
                    </div>
                </Link>
            </div>
        </div>
    </section>
);

export default Diagnosis;